import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { cloneNested } from '@/helpers/helpers'
import useAttachment from '@/views/_global/useAttachment'

export default (props, { emit, root }) => {
  const route = root.$route

  const {
    editingAttachment,
    attachmentList,

    addType,
    editType,

    groupAttachments,
    editAttachment,
    addAttachment,
    handleEditAttachment,
    deleteAttachment,
    download,
  } = useAttachment(emit)

  const resourceId = parseInt(route.params.id, 10)
  const {
    refFormObserver: refScriptFormObserver,
    getValidationState,
    resetForm,
  } = formValidation()

  const loading = ref(false)

  const links = computed(() => groupAttachments(attachmentList.value).ScriptAttachmentUrl ?? [])
  const files = computed(() => groupAttachments(attachmentList.value).ScriptAttachmentFile ?? [])
  const texts = computed(() => groupAttachments(attachmentList.value).ScriptAttachmentText ?? [])

  const fetchAttachments = () => {
    loading.value = true
    store.dispatch(
      'attachment/fetchAttachmentsByResource',
      {
        resource: 'script',
        resourceId,
      },
    )
      .then(response => {
        attachmentList.value = response.data
      })
      .finally(() => {
        loading.value = false
      })
  }

  if (resourceId) {
    fetchAttachments()
  }

  return {
    loading,
    attachmentList,
    editingAttachment,
    links,
    files,
    texts,
    resourceId,
    addType,
    editType,

    refScriptFormObserver,
    getValidationState,
    resetForm,
    editAttachment,
    addAttachment,
    download,
    fetchAttachments,

    deleteAttachment,
    handleEditAttachment,
    cloneNested,
  }
}
