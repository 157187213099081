<template>
  <div>

    <add-attachment-modal
      v-if="attachmentExerciseId"
      resource="exercise"
      :resource-id="attachmentExerciseId"
      type="file"
      :types="['file', 'link']"
      :attachment-list="attachmentList"
    />

    <edit-attachment-modal
      v-if="attachmentExerciseId"
      v-model="editingAttachment"
      resource="exercise"
      :resource-id="attachmentExerciseId"
      :type.sync="editType"
    />

    <attachment-event-handler
      @fetch-attachments="fetchData"
      @start-processing="processing = true"
      @stop-processing="processing = false"
    />

    <div>

      <validation-observer
        ref="refExerciseFormObserver"
        #default="{ handleSubmit }"
      >

        <b-form
          ref="refExerciseForm"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <h2 class="mb-1">
            {{ $t('script.exercise.title') }}
          </h2>

          <b-row>
            <b-col
              cols="12"
              xl="10"
            >
              <!-- Row Loop -->
              <div
                v-for="(item, index) in items"
                :key="index"
              >

                <b-card>

                  <rc-overlay
                    :show="processing"
                    no-fade
                    variant="white"
                    class="h-100"
                  >

                    <!-- Title -->
                    <b-col cols="12">

                      <validation-provider
                        #default="validationContext"
                        :vid="'title-validation-' + index"
                        :name="$t('script.exercise.form.label.title')"
                        rules="required|min:2"
                      >
                        <b-form-group
                          :label="$t('script.exercise.form.label.title')"
                          :label-for="'title-' + index"
                        >

                          <b-input-group>
                            <b-form-input
                              :id="'title-' + index"
                              v-model="item.title"
                              type="text"
                              autocomplete="off"
                              minlength="2"
                              maxlength="250"
                              spellcheck="false"
                              :state="getValidationState(validationContext)"
                            />
                            <b-input-group-append>
                              <b-button
                                variant="outline-danger"
                                :disabled="deleting"
                                @click="removeItem(index, item);"
                              >
                                <feather-icon
                                  icon="TrashIcon"
                                />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col cols="12">
                      <!-- Description -->
                      <text-editor
                        :id="'text-description-' + index"
                        v-model="item.description"
                        variant="simple"
                        :label="$t('script.exercise.form.label.description').toString()"
                        :placeholder="$t('script.exercise.form.label.description-placeholder').toString()"
                      />

                      <p
                        class="text-right mt-n1"
                      >
                        <b-link
                          v-if="item.id"
                          v-b-modal.add-attachment-modal
                          @click="addAttachmentLocal(item.id, item.attachments)"
                        >
                          <small>
                            <feather-icon icon="PlusIcon" /> {{ $t('script.btn.add-attachment').toLowerCase() }}
                          </small>
                        </b-link>
                        <small v-else>
                          <feather-icon icon="InfoIcon" /> {{ $t('script.exercise.form.save-to-add-attachment') }}
                        </small>
                      </p>
                    </b-col>

                    <b-col
                      v-if="item.attachments && item.attachments.length"
                      cols="12"
                    >
                      <h5>{{ $t('shared.attachments') }}</h5>

                      <b-row>
                        <!-- url -->
                        <b-col
                          v-for="(attachment) in groupAttachments(item.attachments).ExerciseAttachmentUrl"
                          :key="'attachment-' + attachment.id"
                          cols="12"
                          xl="6"
                        >
                          <div class="form-control mb-1">

                            <div class="ml-2 float-right">
                              <b-link
                                class="text-danger my-1 ml-1"
                                @click="deleteAttachment(attachment, item.attachments);"
                              >
                                <feather-icon icon="TrashIcon" />
                              </b-link>

                              <b-link
                                v-b-modal.edit-attachment-modal
                                class=" my-1 ml-1"
                                @click="editAttachmentLocal(attachment, item.attachments, 'url')"
                              >
                                <feather-icon icon="EditIcon" />
                              </b-link>
                            </div>

                            <b-link
                              :href="attachment.url"
                              target="_blank"
                              nofollow
                            >
                              <feather-icon icon="LinkIcon" />
                              {{ attachment.title }}
                            </b-link>

                          </div>
                        </b-col>

                        <!-- file -->
                        <b-col
                          v-for="(attachment) in groupAttachments(item.attachments).ExerciseAttachmentFile"
                          :key="'attachment-' + attachment.id"
                          cols="12"
                          xl="6"
                        >
                          <div class="form-control mb-1">

                            <div class="ml-2 float-right">
                              <b-link
                                class="text-danger my-1 ml-1"
                                @click="deleteAttachment(attachment, item.attachments);"
                              >
                                <feather-icon
                                  icon="TrashIcon"
                                />
                              </b-link>

                              <b-link
                                v-b-modal.edit-attachment-modal
                                class=" my-1 ml-1"
                                @click="editAttachmentLocal(attachment, item.attachments, 'file')"
                              >
                                <feather-icon icon="EditIcon" />
                              </b-link>
                            </div>

                            <b-link @click="download(attachment)">
                              <feather-icon icon="DownloadIcon" />
                              {{ attachment.title }}
                            </b-link>

                          </div>
                        </b-col>
                      </b-row>
                    </b-col>

                  </rc-overlay>

                </b-card>

              </div>

              <div>

                <b-card>
                  <rc-overlay
                    :show="processing"
                    no-fade
                    variant="white"
                    class="h-100"
                  >

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      {{ $t('shared.btn.save') }}
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      @click="fetchData"
                    >
                      {{ $t('shared.btn.revert') }}
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="float-right"
                      @click="repeatAgain"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                      {{ $t('shared.btn.add') }}
                    </b-button>

                  </rc-overlay>
                </b-card>

              </div>

            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </div>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Swal from 'sweetalert2'
import TextEditor from '@/views/_global/TextEditor'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import i18n from '@/libs/i18n'
import store from '@/store'
import { parseRequestError } from '@/helpers/helpers'
import useExerciseAttachment from '@/views/organization/script/scripts/tabs/useExerciseAttachment'
import AddAttachmentModal from '@/views/_global/AddAttachmentModal'
import EditAttachmentModal from '@/views/_global/EditAttachmentModal'
import AttachmentEventHandler from '@/views/_global/AttachmentEventHandler'
import useAttachment from '@/views/_global/useAttachment'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,

    TextEditor,
    RcOverlay,
    AddAttachmentModal,
    EditAttachmentModal,
    AttachmentEventHandler,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup(_, { emit, root }) {
    const route = root.$route
    const scriptId = parseInt(route.params.id, 10)

    const processing = ref(true)
    const deleting = ref(false)
    const items = ref([])

    const {
      refFormObserver: refExerciseFormObserver,
      getValidationState,
      resetForm,
      clearForm,
      resetObserver,
    } = formValidation()

    const {
      attachmentExerciseId,
    } = useExerciseAttachment()

    const {
      editingAttachment,
      attachmentList,

      editType,

      groupAttachments,
      editAttachment,
      addAttachment,
      handleNewAttachment,
      handleEditAttachment,
      deleteAttachment,
      download,
    } = useAttachment(emit)

    const editAttachmentLocal = (attachment, attachments, type = 'file') => {
      attachmentExerciseId.value = attachment.id
      editAttachment(attachment, attachments, type)
    }

    const addAttachmentLocal = (itemId, attachments, type = 'file') => {
      attachmentExerciseId.value = itemId
      addAttachment(attachments, type)
    }

    const repeatAgain = () => {
      items.value.push({
        title: '',
        attachments: [],
      })
    }

    const onSubmit = () => {
      processing.value = true
      store.dispatch('script/patchExercises', { scriptId, data: items.value })
        .then(response => {
          items.value = response.data

          if (!response.data.length) {
            repeatAgain()
          }

          window.toast.notify.success(i18n.t('script.scripts.toast.updated'))
          resetObserver()
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          processing.value = false
        })
    }

    const fetchData = () => {
      store.dispatch('script/fetchExercises', { scriptId })
        .then(response => {
          items.value = response.data

          if (!response.data.length) {
            repeatAgain()
          }

          resetObserver()
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          processing.value = false
        })
    }

    const removeItem = (index, item) => {
      if (!item.id) {
        items.value.splice(index, 1)
        return
      }

      deleting.value = true
      store.dispatch('script/getExerciseStatistics', item.id)
        .then(res => {
          if (!res.data.total) {
            items.value.splice(index, 1)
            deleting.value = false
            return
          }

          let subtitle = `${i18n.t('script.exercise.delete-alert.subtitle')}<br/>`

          if (res.data.unchecked > 0) {
            subtitle += `<br>${i18n.tc('script.exercise.delete-alert.subtitle-unchecked-plural', res.data.unchecked, { n: res.data.unchecked })}`
          }
          if (res.data.checked > 0) {
            subtitle += `<br>${i18n.tc('script.exercise.delete-alert.subtitle-checked-plural', res.data.checked, { n: res.data.checked })}`
          }

          Swal.fire({
            title: i18n.t('script.exercise.delete-alert.title'),
            html: subtitle,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: i18n.t('shared.alert.delete.btn-yes'),
            cancelButtonText: i18n.t('shared.alert.delete.btn-no'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              items.value.splice(index, 1)
            }
            deleting.value = false
          })
        })
    }

    fetchData()
    return {
      processing,
      deleting,
      items,

      // methods
      onSubmit,
      removeItem,
      repeatAgain,
      fetchData,

      // validations
      refExerciseFormObserver,
      getValidationState,
      resetForm,
      clearForm,

      // attachments
      attachmentExerciseId,
      editType,
      editingAttachment,
      attachmentList,
      groupAttachments,
      handleNewAttachment,
      handleEditAttachment,
      editAttachmentLocal,
      addAttachmentLocal,
      deleteAttachment,
      download,
    }
  },
}
</script>
