<template>
  <div>
    <div>

      <validation-observer
        ref="refKnowledgeChecklistFormObserver"
        #default="{ handleSubmit }"
      >

        <b-form
          ref="refKnowledgeChecklistForm"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <h2 class="mb-1">
            {{ $t('script.knowledge-checklist.title') }}
          </h2>

          <b-row>
            <b-col
              cols="12"
              xl="10"
            >

              <!-- Row Loop -->
              <div
                v-for="(item, index) in items"
                :key="index"
              >
                <b-card>

                  <rc-overlay
                    :show="processing"
                    no-fade
                    variant="white"
                    class="h-100"
                  >

                    <!-- Title -->
                    <b-col cols="12">

                      <validation-provider
                        #default="validationContext"
                        :vid="'title-validation-' + index"
                        :name="$t('script.knowledge-checklist.form.label.title')"
                        rules="required|min:2"
                      >
                        <b-form-group
                          :label="$t('script.knowledge-checklist.form.label.title')"
                          :label-for="'title-' + index"
                        >

                          <b-input-group>
                            <b-form-input
                              :id="'title-' + index"
                              v-model="item.title"
                              type="text"
                              autocomplete="off"
                              minlength="2"
                              maxlength="250"
                              spellcheck="false"
                              :state="getValidationState(validationContext)"
                            />
                            <b-input-group-append>
                              <b-button
                                variant="outline-danger"
                                :disabled="deleting"
                                @click="removeItem(index, item)"
                              >
                                <feather-icon
                                  icon="TrashIcon"
                                />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col cols="12">
                      <!-- Description for student -->
                      <text-editor
                        :id="'text-description-' + index"
                        v-model="item.description"
                        variant="simple"
                        :label="$t('script.knowledge-checklist.form.label.description').toString()"
                        :placeholder="$t('script.knowledge-checklist.form.label.description-placeholder').toString()"
                      />
                    </b-col>

                    <b-col cols="12">
                      <!-- Example questions -->
                      <text-editor
                        :id="'text-example-questions-' + index"
                        v-model="item.exampleQuestions"
                        variant="simple"
                        :label="$t('script.knowledge-checklist.form.label.example-questions').toString()"
                        :placeholder="$t('script.knowledge-checklist.form.label.example-questions-placeholder').toString()"
                      />
                    </b-col>

                  </rc-overlay>

                </b-card>
              </div>

              <b-card>

                <rc-overlay
                  :show="processing"
                  no-fade
                  variant="white"
                  class="h-100"
                >

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ $t('shared.btn.save') }}
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    @click="fetchData"
                  >
                    {{ $t('shared.btn.revert') }}
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="float-right"
                    @click="repeatAgain"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    {{ $t('shared.btn.add') }}
                  </b-button>

                </rc-overlay>
              </b-card>

            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </div>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Swal from 'sweetalert2'
import TextEditor from '@/views/_global/TextEditor'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import i18n from '@/libs/i18n'
import store from '@/store'
import { parseRequestError } from '@/helpers/helpers'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,

    TextEditor,
    RcOverlay,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup(_, { root }) {
    const route = root.$route
    const scriptId = parseInt(route.params.id, 10)

    const processing = ref(true)
    const deleting = ref(false)
    const items = ref([])

    const {
      refFormObserver: refKnowledgeChecklistFormObserver,
      getValidationState,
      resetForm,
      clearForm,
      resetObserver,
    } = formValidation()

    const repeatAgain = () => {
      items.value.push({
        title: '',
      })
    }

    const onSubmit = () => {
      processing.value = true
      store.dispatch('script/patchKnowledgeChecks', { scriptId, data: items.value })
        .then(response => {
          items.value = response.data

          if (!response.data.length) {
            repeatAgain()
          }

          window.toast.notify.success(i18n.t('script.scripts.toast.updated'))
          resetObserver()
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          processing.value = false
        })
    }

    const fetchData = () => {
      store.dispatch('script/fetchKnowledgeChecks', { scriptId })
        .then(response => {
          items.value = response.data

          if (!response.data.length) {
            repeatAgain()
          }

          resetObserver()
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          processing.value = false
        })
    }

    const removeItem = (index, item) => {
      if (!item.id) {
        items.value.splice(index, 1)
        return
      }

      deleting.value = true
      store.dispatch('script/getKnowledgeCheckStatistics', item.id)
        .then(res => {
          if (!res.data.total) {
            items.value.splice(index, 1)
            deleting.value = false
            return
          }

          let subtitle = `${i18n.t('script.knowledge-checklist.delete-alert.subtitle')}<br/>`

          if (res.data.unchecked > 0) {
            subtitle += `<br>${i18n.tc('script.knowledge-checklist.delete-alert.subtitle-unchecked-plural', res.data.unchecked, { n: res.data.unchecked })}`
          }
          if (res.data.checked > 0) {
            subtitle += `<br>${i18n.tc('script.knowledge-checklist.delete-alert.subtitle-checked-plural', res.data.checked, { n: res.data.checked })}`
          }

          Swal.fire({
            title: i18n.t('script.knowledge-checklist.delete-alert.title'),
            html: subtitle,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: i18n.t('shared.alert.delete.btn-yes'),
            cancelButtonText: i18n.t('shared.alert.delete.btn-no'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              items.value.splice(index, 1)
            }
            deleting.value = false
          })
        })
    }

    fetchData()
    return {
      processing,
      deleting,
      items,

      // methods
      onSubmit,
      removeItem,
      repeatAgain,
      fetchData,

      // validations
      refKnowledgeChecklistFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    }
  },
}
</script>
