<template>
  <div>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refScriptFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>
          <b-col
            cols="12"
            xl="10"
          >

            <b-card>
              <rc-overlay
                :show="loading"
                no-fade
                variant="white"
                class="h-100"
              >

                <!-- Subject -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('script.scripts.label.subject')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('script.scripts.label.subject')"
                    label-for="full-name"
                  >
                    <b-form-input
                      id="full-name"
                      v-model="localScriptData.subject"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- Script Group -->
                <b-form-group
                  :label="$t('script.scripts.label.script-group')"
                  label-for="script-group"
                >
                  <v-select
                    v-model="localScriptData.scriptGroup"
                    :options="scriptGroups"
                    label="name"
                    input-id="script-group"
                  >

                    <template #no-options>
                      {{ $t('shared.no-data') }}
                    </template>

                  </v-select>
                </b-form-group>

                <!-- Coursebook notes -->
                <validation-provider
                  v-if="localScriptData.scriptGroup && localScriptData.scriptGroup.coursebook"
                  #default="validationContext"
                  :name="$t('script.scripts.label.coursebook-notes')"
                  rules=""
                >
                  <b-form-group
                    :label="$t('script.scripts.label.coursebook-notes')"
                    label-for="username"
                  >
                    <b-form-input
                      id="coursebook"
                      v-model="localScriptData.coursebookNotes"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- Content -->
                <text-editor
                  id="content"
                  v-model="localScriptData.content"
                  :label="$t('script.scripts.label.content').toString()"
                  :placeholder="$t('script.scripts.label.content-placeholder').toString()"
                />

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ localScriptData.id ? $t('shared.btn.update') : $t('shared.btn.add') }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    :to="{name: 'organization-script-list'}"
                  >
                    {{ $t('shared.btn.cancel') }}
                  </b-button>
                </div>

              </rc-overlay>
            </b-card>

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BRow, BCol, VBModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ref, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import i18n from '@/libs/i18n'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import TextEditor from '@/views/_global/TextEditor'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCard,
    BRow,
    BCol,

    RcOverlay,
    vSelect,
    TextEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, {
    emit,
    root,
  }) {
    const route = root.$route
    const router = root.$router
    const {
      refFormObserver: refScriptFormObserver,
      getValidationState,
      resetForm,
      resetObserver,
    } = formValidation()

    const loading = ref(false)

    const localScriptData = ref(cloneNested(store.state.script.scriptModel))
    const scriptGroups = computed(() => store.state.scriptGroup.scriptGroups)

    store.dispatch('scriptGroup/fetchScriptGroups')

    if (route.params.id) {
      loading.value = true
      store.dispatch('script/fetchScript', route.params.id)
        .then(() => {
          localScriptData.value = cloneNested(store.state.script.script)
        })
        .catch(err => {
          parseRequestError(err)
          router.push({ name: 'organization-script-list' })
        })
        .finally(() => {
          loading.value = false
        })
    }

    const dispatchForm = preparedData => {
      if (preparedData.id) {
        return store.dispatch(
          'script/patchScript',
          {
            id: preparedData.id,
            data: preparedData,
          },
        )
      }

      return store.dispatch('script/addScript', preparedData)
        .then(res => {
          store.commit('script/SET_SCRIPT', res.data)
          router.push({ name: 'organization-script-edit', params: { id: res.data.id } })
        })
    }

    const onSubmit = () => {
      const preparedData = cloneNested(localScriptData.value)
      if (preparedData.scriptGroup) {
        preparedData.scriptGroup = preparedData.scriptGroup.id
      }

      dispatchForm(preparedData)
        .then(() => {
          if (preparedData.id) {
            window.toast.notify.success(i18n.t('script.scripts.toast.updated'))
          } else {
            window.toast.notify.success(i18n.t('script.scripts.toast.added'))
          }

          emit('refetch-data')
          emit('update:is-script-form-sidebar-active', false)
          resetObserver()
        })
        .catch(res => {
          window.toast.notify.danger(i18n.t('script.scripts.toast.fail'), res.message)
        })
    }

    return {
      loading,
      localScriptData,
      onSubmit,
      scriptGroups,

      refScriptFormObserver,
      getValidationState,
      resetForm,
      resetObserver,
    }
  },
}
</script>
