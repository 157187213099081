<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab
      active
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('script.general.title') }}
        </span>
      </template>

      <general />
    </b-tab>
    <!--/ general tab -->

    <!-- attachments tab -->
    <b-tab
      lazy
      :disabled="!script.id"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="PaperclipIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('script.attachments.title') }}
        </span>
      </template>

      <attachments />
    </b-tab>
    <!--/ attachments tab -->

    <!-- Exercises tab -->
    <b-tab
      lazy
      :disabled="!script.id"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="HomeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('script.exercise.title') }}
        </span>
      </template>

      <exercise />
    </b-tab>
    <!--/ Exercises tab -->

    <!-- Checklist tab -->
    <b-tab
      lazy
      :disabled="!script.id"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="CheckIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('script.knowledge-checklist.title') }}
        </span>
      </template>

      <knowledge-checklist />
    </b-tab>
    <!--/ Checklist tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import General from '@/views/organization/script/scripts/tabs/General'
import Attachments from '@/views/organization/script/scripts/tabs/Attachments'
import Exercise from '@/views/organization/script/scripts/tabs/Exercise'
import KnowledgeChecklist from '@/views/organization/script/scripts/tabs/KnowledgeChecklist'

export default {
  components: {
    BTabs,
    BTab,
    General,
    Attachments,
    Exercise,
    KnowledgeChecklist,
  },
  computed: {
    script() { return this.$store.getters['script/getScript'] },
  },
  beforeCreate() {
    this.$store.dispatch('script/resetScript')
  },
}
</script>
